import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";
import { Spinner } from "react-bootstrap";
import { getLoggedUser } from "../../state/user";
import {
  checkUserValidity,
  bidNow,
  getTopBidders,
  maskClasses,
  sendNotification,
} from "../../models/auctions";
import Countdown from "react-countdown";
import swal from "sweetalert";
//import worker_script from "../../worker";

//import serverDate from "../../utils/serverDate";

let lang = localStorage.getItem("I18N_LANGUAGE");
let styleClose =
  lang === "ar" ? { fontSize: "55%", textAlign: "right" } : { fontSize: "55%" };
let refreshStyle =
  lang === "ar"
    ? { position: "absolute", left: "7px" }
    : { position: "absolute", right: "7px" };
const user = getLoggedUser();
let is_logged = localStorage.getItem("is_logged_in") ? true : false;
const FinishBid = () => <span>Bid time ends!!!</span>;
const FinishEnroll = () => (
  <small className="small text text-danger" style={styleClose}>
    {i18n.t("reg_closed")}
  </small>
);

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <FinishBid />;
  } else {
    // Render a countdown
    return (
      <span>
        <span className="text text-primary">{days}</span> {i18n.t("days")} :
        <span className="text text-primary">{hours}</span> {i18n.t("hours")} :
        <span className="text text-primary">{minutes}</span> {i18n.t("minutes")}{" "}
        :<span className="text text-primary">{seconds}</span>{" "}
        {i18n.t("seconds")}
      </span>
    );
  }
};

let rendererList = function ({ days, hours, minutes, seconds, completed }) {
  if (completed) {
    return <FinishBid />;
  } else {
    let blinkBg = "";
    let childClass = "text text-primary";
    if (days === 0 && hours === 0 && minutes < 3) {
      blinkBg = "blink-bg";
      childClass = "text text-white";
    }
    return (
      <span className={blinkBg}>
        <span className={childClass}>
          <strong>
            {days}:{hours}:{minutes}:{seconds}
          </strong>
        </span>
      </span>
    );
  }
};

let rendererListEnroll = function ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) {
  if (completed) {
    return <FinishEnroll />;
  } else {
    let blinkBg = "";
    let childClass = "text text-primary";
    if (days === 0 && hours === 0 && minutes < 3) {
      blinkBg = ""; //blink-bg
      childClass = "text text-danger";
    }
    return (
      <span className={blinkBg} style={{ fontSize: "11px" }}>
        <span className={childClass} style={{ fontSize: "11px" }}>
          <strong>
            {days}:{hours}:{minutes}:{seconds}
          </strong>
        </span>
      </span>
    );
  }
};

export class AuctionRow extends React.Component {
  constructor() {
    super();
    this.state = {
      bidTimeStopped: false,
      lgShow: false,
      showDetails: false,
      user_valid: false,
      is_loading: false,
      current_bid_amount: 0,
      user_bid_amount: 0,
      bid_increment: 0,
      left_button_disable: true,
      top_bidders: {},
      bid_place_message: "",
      toggleArrow: false,
      bid_place_error: false,
      date_out_of_range: false,
      descTab: "tab1",
      is_bid_sending: false,
      bid_count: 0,
      winner: {},
      is_completed: false,
      auction_page: "",
      myPosition: false,
      myPrice: false,
      refresh_loading: false,
      is_blacklisted: false,
      increments: [5, 10, 15, 20, 25],
      now: () => {
        let time = new Date().toLocaleTimeString("en-US", {
          timeZone: "Asia/Muscat",
        });
        let dt = new Date().toISOString("en-US").split("T")[0];
        let newDt = dt + " " + time;
        newDt = new Date(newDt.replace(/-/g, "/"));
        return newDt;
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.incrementNumber = this.incrementNumber.bind(this);
    this.decrementNumber = this.decrementNumber.bind(this);
    this.bidNow = this.bidNow.bind(this);
    this.loadTopBidders = this.loadTopBidders.bind(this);
    this.auctionFinished = this.auctionFinished.bind(this);
    this.isComplete = this.isComplete.bind(this);
    this.checkIsComplete = this.checkIsComplete.bind(this);
  }

  componentDidMount() {
    // this.worker = new Worker(worker_script);
    // this.worker.onmessage = (ev) => {
    //   console.log("got data back from worker");
    //   console.log(ev);
    // };
    //button event
    //this.worker.postMessage("Button clicked");

    let start_amount = parseFloat(this.props.item.start_amount);
    let current_bid_amount = parseFloat(start_amount);
    let bid_increment = 0;
    if (this.props.item.bid_increment) {
      bid_increment = this.props.item.bid_increment;
      this.setState({ bid_increment: bid_increment });
    }
    if (this.props.item.latest_bid && this.props.item.latest_bid.bid_amount) {
      current_bid_amount = parseFloat(this.props.item.latest_bid.bid_amount);
    }
    let bid_count = this.props.item.bid_count;
    let winner = this.props.item.winner;
    let is_completed = this.props.item.is_completed;
    let auction_page = this.props.auction_page;
    let increments = this.props.item.increment_numbers;

    let user_bid_amount =
      parseFloat(start_amount) + parseFloat(this.state.bid_increment);
    if (current_bid_amount === start_amount) {
      user_bid_amount = start_amount + parseFloat(bid_increment);
    }
    this.setState({
      user_bid_amount: user_bid_amount,
      current_bid_amount,
      is_loading: true,
      bid_count,
      winner,
      is_completed,
      auction_page,
      increments: increments,
    });
    //this.loadTopBidders();
  }

  async auctionFinished() {
    let auction = this.props.item.id;
    await sendNotification({ auction: auction });
  }

  isComplete() {
    this.setState({ bidTimeStopped: true });
  }

  checkIsComplete() {
    this.setState({ bidTimeStopped: true });
  }

  async loadTopBidders() {
    this.setState({ loading: true });
    this.setState({ refresh_loading: true });
    const response = await getTopBidders({ auction: this.props.item.id });
    let data = response.data;
    this.setState({ top_bidders: data });
    if (response && response.meta && response.meta.bid_amount) {
      let current_bid_amount = parseFloat(response.meta.bid_amount);
      let bid_count = response.meta.bid_count;
      let user_bid_amount =
        parseFloat(current_bid_amount) + parseFloat(this.state.bid_increment);
      this.setState({ current_bid_amount, user_bid_amount, bid_count });
    }
    if (response && response.meta && response.meta.myPosition) {
      let myBidData = response.meta.myPosition;
      this.setState({
        myPosition: myBidData.position,
        myPrice: myBidData.bid_amount,
      });
    }
    if (data) {
      this.setState({ refresh_loading: false });
    }
  }

  handleChange(event) {
    if (event.target.name === "bid_increment") {
      this.setCurrentBidAmount(this.state.bid_increment, event.target.value);
    }
    let state = this.state;
    state[event.target.name] = event.target.value;
    this.setState({ state });
  }

  async checkUserAuctionValidity(auction) {
    this.setState({ is_loading: true });
    const response = await checkUserValidity({ auction: auction });
    let data = response.data;
    if (data) {
      this.setState({ is_blacklisted: data.blackList });
      this.setState({ user_valid: data.valid });
      this.setState({ date_out_of_range: data.date_out_of_range });
      this.setState({ is_loading: false });
    }
  }

  setLgShow(opt, auction) {
    if (opt) {
      this.checkUserAuctionValidity(auction);
      this.loadTopBidders();
    }
    this.setState({ lgShow: opt });
  }
  toggleShowDetails() {
    let showDetails = this.state.showDetails;
    this.setState({ showDetails: !showDetails });
  }

  setCurrentBidAmount(old_incr, new_incr) {
    let user_bid_amount = this.state.user_bid_amount;
    let current_amount = parseFloat(this.state.current_bid_amount);
    user_bid_amount = parseFloat(current_amount) + parseFloat(new_incr);
    this.setState({ user_bid_amount });
  }

  decrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) - parseFloat(bid_increment);
    let left_button_disable = false;
    let minAmount = parseFloat(current_amount) + parseFloat(bid_increment);
    if (user_bid_amount <= minAmount) {
      left_button_disable = true;
    }
    this.setState({
      user_bid_amount: user_bid_amount,
      left_button_disable: left_button_disable,
    });
  }

  incrementNumber() {
    let current_amount = parseFloat(this.state.current_bid_amount);
    let user_bid_amount = parseFloat(this.state.user_bid_amount);
    let bid_increment = parseFloat(this.state.bid_increment);
    user_bid_amount = parseFloat(user_bid_amount) + parseFloat(bid_increment);
    let left_button_disable = true;
    if (user_bid_amount > current_amount) {
      left_button_disable = false;
    }
    this.setState({
      user_bid_amount: user_bid_amount,
      left_button_disable: left_button_disable,
    });
  }

  async bidNow() {
    let { user_bid_amount } = this.state;
    if (!user_bid_amount) {
      swal("Failed", "Bid amount is empty", "error");
      return;
    }
    this.setState({ is_bid_sending: true });
    let auction = this.props.item;
    const response = await bidNow({ user_bid_amount, auction: auction.id });
    let data = response.data;
    let current_amount = data.current_amount;
    let message =
      lang === "ar" && response.message_ar.trim() !== ""
        ? response.message_ar
        : response.message;
    this.setState({
      current_bid_amount: current_amount,
      bid_place_message: message,
      bid_place_error: data.bid_place_error,
    });

    if (data.is_error) {
      swal("Failed", message, "error");
    }

    if (data) {
      this.setState({ is_bid_sending: false });
    }
    setTimeout(() => {
      this.setState({
        bid_place_message: "",
        bid_place_error: false,
      });
    }, 5000);

    this.loadTopBidders();
  }

  changeDateFormat(dt) {
    return dt ? new Date(dt.replace(/-/g, "/")) : null;
  }

  render() {
    let now = this.state.now;
    let auction = this.props.item;
    let enquiries = auction.enquiry;
    let reg_status = auction.registartion_status;
    let enquiry_length =
      (auction && auction.enquiry && auction.enquiry.length) || 0;
    let index = this.props.index;
    let slno = Number(index) + 1;
    let valid_user = this.state.user_valid;
    let is_blacklisted = this.state.is_blacklisted;
    let endTime = new Date(this.changeDateFormat(auction.end_date)).getTime();
    let enrollTime = new Date(
      this.changeDateFormat(auction.group_details.reg_end_date)
    ).getTime();

    let toggleClasses = "";
    if (this.state.toggleArrow) {
      toggleClasses =
        "nav nav-fill text-uppercase small position-relative flex-nowrap justify-content-end";
    } else {
      toggleClasses =
        "nav nav-fill text-uppercase small position-relative flex-nowrap";
    }

    //let counterHidden = bidTimeStopped ? { display: "none" } : {};
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let bid_place_error = this.state.bid_place_error;
    let top_bidders = this.state.top_bidders;
    let bidTimeStopped = this.state.bidTimeStopped;
    let regTimeStopped = false;
    let today = new Date();
    let regDt = new Date(this.changeDateFormat(auction.reg_end_date));
    if (regDt < today) {
      regTimeStopped = true;
    }
    // if (top_bidders.length > 0) {
    //   top_bidders.sort((a, b) => (b.bid_amount > a.bid_amount ? 1 : -1));
    // }

    let is_enrolled = this.props.is_enrolled;
    let user_role = user && user.role ? user.role : "U";

    if (user_role === "A" || user_role === "VA") {
      is_enrolled = true;
    }

    if (is_logged && !is_enrolled && !regTimeStopped && user_role === "U") {
      window.location.href = "/enroll/" + auction.group_details.id;
    }

    let { current_bid_amount } = this.state;
    //let total_amount = 0;
    //total_amount = current_bid_amount + current_bid_amount * 0.05;
    //total_amount = current_bid_amount;

    let { increments } = this.state;
    return (
      <React.Fragment>
        <Modal
          size="lg"
          show={this.state.lgShow}
          onHide={() => this.setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {auction.phone_number}{" "}
              <span className="badge badge-dark">
                {i18n.t(maskClasses[auction.class].toLowerCase())}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.is_loading && (
              <div style={{ textAlign: "center" }}>
                <Spinner animation="border" />
              </div>
            )}

            {is_blacklisted && !this.state.is_loading && (
              <div className="text-center">
                <p className="text text-danger text-center">
                  Not authorised to bid auctions. Your phone number is
                  blacklisted. Please contact administrator.
                </p>
              </div>
            )}

            {!is_blacklisted &&
              !this.state.is_loading &&
              is_logged &&
              !valid_user &&
              reg_status === "open" && (
                <div className="text-center">
                  <p className="text text-danger text-center">
                    {lang === "ar" && (
                      <span>
                        يرجى التأكد من دفع رسوم التسجيل للسماح لك بالمزايدة
                      </span>
                    )}
                    {lang === "en" && (
                      <span>
                        Please ensure to pay the registration fee to allow you
                        for bidding.
                      </span>
                    )}
                  </p>
                  <Link
                    to={"/enroll/" + auction.id}
                    className="btn btn-warning"
                  >
                    Enroll Now
                  </Link>
                </div>
              )}

            {!is_blacklisted &&
              !this.state.is_loading &&
              is_logged &&
              !valid_user &&
              reg_status === "closed" && (
                <div className="text-center">
                  <p className="text text-danger text-center">
                    Enrollment closed!!!. <br />
                    Enrollment started on{" "}
                    {auction.group_details.reg_start_date_formatted} & ends on{" "}
                    {auction.group_details.reg_end_date_formatted}
                  </p>
                </div>
              )}

            {!is_blacklisted &&
              !this.state.is_loading &&
              is_logged &&
              valid_user && (
                <div>
                  <div className="row">
                    <div className="col-12">
                      <div className="item-card9-desc mb-2">
                        <a href={true} className="me-4">
                          <span className="">{i18n.t("start_date")} : </span>
                          <span className="text text-primary">
                            {auction.start_date_formatted}
                          </span>
                        </a>
                        <a href={true} className="me-4">
                          <span className="">{i18n.t("end_date")} : </span>
                          <span className="text text-primary">
                            {auction.end_date_formatted}
                          </span>
                        </a>

                        <a href={true} className="me-4">
                          <span className="">{i18n.t("incr_amount")} : </span>
                          <span className="text text-primary">
                            {i18n.t("omr")} {auction.bid_increment}
                          </span>
                        </a>
                        <a href={true} className="me-4">
                          <span className="">{i18n.t("bid_count")} : </span>
                          <span className="text text-primary">
                            {this.state.bid_count}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <a
                            href={true}
                            className="btn bg-danger-transparent text-danger mb-4 btn-lg btn-block"
                            style={{ textAlign: "center" }}
                          >
                            <Countdown date={endTime} renderer={renderer} />
                            {/* now={() => {
                              return serverDate(new Date());
                            }} */}
                          </a>

                          <button className="btn bg-light-transparent text-primary mb-0 btn-lg btn-block">
                            {i18n.t("current_amount")} :{" "}
                            <b>
                              {i18n.t("omr")} {current_bid_amount}
                            </b>
                          </button>
                          {/* <button className="btn bg-light-transparent text-primary m-0 btn-md btn-block">
                            ({i18n.t("current_amount")} + 5%):{" "}
                            <b>
                              {i18n.t("omr")} {total_amount}
                            </b>
                          </button> */}

                          <div className="container">
                            <div className="d-flex align-items-center">
                              <div className="flex-shrink-0">
                                <a
                                  href={true}
                                  className="btn-left btn-link p-2 toggle text-dark"
                                  onClick={(event) => {
                                    this.setState({ toggleArrow: false });
                                  }}
                                >
                                  <i className="fa fa-chevron-left"></i>
                                </a>
                              </div>
                              <div className="flex-grow-1 w-100 o-hidden bid-amounts">
                                <ul className={toggleClasses}>
                                  {increments.map((incr, i) => {
                                    return (
                                      <li className="nav-item" key={i}>
                                        <button
                                          className={
                                            Number(this.state.bid_increment) ===
                                            Number(incr)
                                              ? "btn btn-primary"
                                              : "btn btn-light"
                                          }
                                          onClick={() => {
                                            this.setState({
                                              bid_increment: incr,
                                            });
                                            this.setCurrentBidAmount(
                                              this.state.bid_increment,
                                              incr
                                            );
                                          }}
                                        >
                                          {incr}
                                        </button>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                              <hr />
                              <div className="flex-shrink-0">
                                <a
                                  href={true}
                                  className="btn-right btn-link toggle p-2 text-dark"
                                  onClick={(event) => {
                                    this.setState({ toggleArrow: true });
                                  }}
                                >
                                  <i className="fa fa-chevron-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="container mb-2">
                            <div className="form-group">
                              <label className="form-label">
                                {i18n.t("latest_bid_amount")}
                              </label>
                              <div className="input-group">
                                <span className="input-group-btn">
                                  <button
                                    type="button"
                                    className="btn btn-default btn-number"
                                    disabled={this.state.left_button_disable}
                                    onClick={this.decrementNumber}
                                  >
                                    <span className="glyphicon glyphicon-minus"></span>
                                  </button>
                                </span>
                                <input
                                  type="number"
                                  name="user_bid_amount"
                                  className="form-control input-number"
                                  placeholder="Bid Amount"
                                  value={this.state.user_bid_amount}
                                  onChange={this.handleChange}
                                  readOnly={true}
                                />
                                <span className="input-group-btn">
                                  <button
                                    type="button"
                                    className="btn btn-default btn-number"
                                    onClick={this.incrementNumber}
                                  >
                                    <span className="glyphicon glyphicon-plus"></span>
                                  </button>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="container mb-2">
                            {is_logged && valid_user && !bidTimeStopped && (
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-success btn-block mb-4 text-white"
                                  onClick={this.bidNow}
                                  disabled={this.state.is_bid_sending}
                                >
                                  {" "}
                                  {this.state.is_bid_sending && (
                                    <span>
                                      <span
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      Sending...
                                    </span>
                                  )}
                                  {!this.state.is_bid_sending &&
                                    i18n.t("bid_now")}
                                </button>
                                {this.state.bid_place_message && (
                                  <div
                                    className={
                                      bid_place_error
                                        ? "alert alert-danger"
                                        : "alert alert-success"
                                    }
                                  >
                                    {this.state.bid_place_message}
                                  </div>
                                )}
                              </div>
                            )}

                            {(!is_logged || !valid_user) && (
                              <Link
                                to={"/enroll/" + auction.id}
                                className="btn btn-light btn-block mb-4"
                              >
                                {i18n.t("enroll")}
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title">
                            {i18n.t("top_3_bidders")}
                          </h3>
                          <button
                            className="btn btn-primary"
                            style={refreshStyle}
                            onClick={this.loadTopBidders}
                          >
                            <i
                              className={
                                this.state.refresh_loading
                                  ? "fa fa-spinner"
                                  : "fa fa-refresh"
                              }
                            ></i>{" "}
                            {this.state.refresh_loading
                              ? i18n.t("refreshing")
                              : i18n.t("refresh")}
                          </button>
                        </div>
                        <div className="card-body">
                          {this.state.top_bidders &&
                            this.state.top_bidders.length > 0 && (
                              <table className="table table-stripped">
                                <thead>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      {i18n.t("bidder_id")}
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      {i18n.t("rank")}
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      {i18n.t("amount")}
                                    </th>
                                    {/* <th style={{ textAlign: "center" }}>
                                      {i18n.t("total")}
                                    </th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {top_bidders.map((item, i) => {
                                    let total_bid_amount = Number(
                                      item.bid_amount
                                    );
                                    return (
                                      <tr key={i}>
                                        <td style={{ textAlign: "center" }}>
                                          <div>{item.enroll_id}</div>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <span
                                            className={
                                              Number(item.user_id) ===
                                              Number(user.id)
                                                ? "badge badge-warning me-2"
                                                : "badge badge-pill badge-info me-2"
                                            }
                                          >
                                            {Number(item.user_id) ===
                                              Number(user.id) &&
                                              i18n.t("my_rank") + " : "}

                                            {i + 1}
                                          </span>
                                        </td>
                                        {/* <td>{item.bid_amount}</td> */}
                                        <td>
                                          <b>{total_bid_amount}</b>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            )}

                          {this.state.top_bidders &&
                            this.state.top_bidders.length < 1 && (
                              <div>No Bidders Found!!!</div>
                            )}
                        </div>
                      </div>

                      {this.state.myPosition && (
                        <div className="card">
                          <div className="card-body">
                            <h3 className="card-title text-center">
                              {i18n.t("your_position")}
                            </h3>
                            <div className="d-flex align-items-center flex-column">
                              <span className="badge badge-warning">
                                {i18n.t("rank")} : {this.state.myPosition}
                              </span>
                              <span>
                                {i18n.t("bid_amount")} : {this.state.myPrice}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      {/* <p>
                        <a
                          className="btn btn-info btn-sm"
                          data-toggle="collapse"
                          href={true}
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                          onClick={() => this.toggleShowDetails()}
                        >
                          {this.state.showDetails && (
                            <span>
                              <i className="fa fa-minus"></i>&nbsp;
                              {i18n.t("hide_details")}
                            </span>
                          )}
                          {!this.state.showDetails && (
                            <span>
                              <i className="fa fa-plus"></i>&nbsp;
                              {i18n.t("show_details")}
                            </span>
                          )}
                        </a>
                      </p> */}
                      <div
                        className={
                          this.state.showDetails
                            ? "card collapse show"
                            : "card collapse hide"
                        }
                      >
                        <div className="">
                          <div className="border-0 mb-5">
                            <div className="wideget-user-tab wideget-user-tab3">
                              <div className="tab-menu-heading">
                                <div className="tabs-menu1">
                                  <ul className="nav">
                                    <li className="">
                                      <a
                                        style={{ cursor: "pointer" }}
                                        className="active"
                                        data-bs-toggle="tab"
                                        href={true}
                                        onClick={(event) => {
                                          this.setState({ descTab: "tab1" });
                                        }}
                                      >
                                        {i18n.t("description")}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        data-bs-toggle="tab"
                                        className=""
                                        href={true}
                                        onClick={(event) => {
                                          this.setState({ descTab: "tab2" });
                                        }}
                                      >
                                        {i18n.t("enquiry")}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        style={{ cursor: "pointer" }}
                                        data-bs-toggle="tab"
                                        className=""
                                        href={true}
                                        onClick={(event) => {
                                          this.setState({ descTab: "tab3" });
                                        }}
                                      >
                                        {i18n.t("terms_conditions")}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="tab-content border-start border-end border-top br-ts-3 p-5 bg-white">
                              <div
                                className={
                                  this.state.descTab === "tab1"
                                    ? "tab-pane active"
                                    : "tab-pane"
                                }
                              >
                                <h3 className="card-title mb-3 font-weight-semibold">
                                  {i18n.t("details")}
                                </h3>
                                <div className="row">
                                  <div className="col-xl-12 col-md-12">
                                    {auction.description}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  this.state.descTab === "tab2"
                                    ? "tab-pane active"
                                    : "tab-pane"
                                }
                              >
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="table-responsive">
                                      {enquiry_length > 0 && (
                                        <table className="table table-bordered border-top mb-0">
                                          <tbody>
                                            {enquiries.map((item, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td>{item.label}</td>
                                                  <td>{item.value}</td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  this.state.descTab === "tab3"
                                    ? "tab-pane active"
                                    : "tab-pane"
                                }
                              >
                                <h3 className="card-title mb-3 font-weight-semibold">
                                  {i18n.t("terms_conditions")}
                                </h3>
                                <div className="row">
                                  <div
                                    className="col-xl-12 col-md-12"
                                    style={{ whiteSpace: "pre-wrap" }}
                                  >
                                    {current_lng === "ar"
                                      ? auction.terms_arabic
                                      : auction.terms}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </Modal.Body>
        </Modal>
        <tr key={index}>
          <td width={50}>
            <span>
              <strong>{slno}</strong>
            </span>
          </td>
          <td className="number auc-num">
            <span className="d-flex flex-column">
              <span>
                <strong>{auction.phone_number}</strong>
              </span>
              {user && user.role === "U" && is_logged && (
                <Countdown date={enrollTime} renderer={rendererListEnroll} />
                // now={() => {
                //   return serverDate(new Date());
                // }}
              )}
            </span>
          </td>
          <td>
            <small
              style={{ fontSize: "100%" }}
              className={
                auction.class === "G"
                  ? "badge badge-warning w-100"
                  : "badge badge-light w-100"
              }
            >
              {i18n.t(maskClasses[auction.class].toLowerCase())}
            </small>
          </td>
          {is_enrolled && (
            <td>
              {i18n.t("omr")} {current_bid_amount}
            </td>
          )}
          {/* {is_enrolled && (
            <td>
              {i18n.t("omr")} {total_amount}
            </td>
          )} */}
          {/* <td className="d-none d-md-block">{auction.start_date_formatted}</td> */}
          {is_logged && is_enrolled && (
            <td>
              <Countdown
                date={endTime}
                renderer={rendererList}
                onComplete={this.isComplete}
                onPause={this.checkIsComplete}
                now={now}
              />
              {/*  */}
              {/* now={() => {
                return serverDate(new Date());
              }} */}
              {this.state.auction_page === "my_auctions" &&
                this.state.is_completed && (
                  <div>
                    {this.state.winner &&
                      this.state.winner.user_id === user.id && (
                        <span className="badge badge-success">
                          You are the winner
                        </span>
                      )}
                  </div>
                )}
              <div>
                <small className="small text-muted" style={{ fontSize: "65%" }}>
                  {i18n.t("start_date") + " : " + auction.start_date_formatted}
                </small>
              </div>
            </td>
          )}
          {is_logged && is_enrolled && <td>{this.state.bid_count}</td>}
          {(this.state.auction_page === "my_auctions" ||
            this.state.auction_page === "enrolled_auctions") && (
            <td>{auction.my_rank}</td>
          )}
          {is_logged && (
            <td className="text text-center">
              {user &&
                user.role === "U" &&
                is_logged &&
                !bidTimeStopped &&
                is_enrolled &&
                auction.status === "A" && (
                  <button
                    className="btn btn-primary btn-sm btn-block font-1vw"
                    onClick={() => this.setLgShow(true, auction.id)}
                  >
                    {i18n.t("bid_now")}
                  </button>
                )}
              {!is_enrolled && !regTimeStopped && user_role === "U" && (
                <a
                  href={"enroll/" + auction.group_details.id}
                  className="btn btn-primary"
                >
                  {i18n.t("enroll_paynow")}
                </a>
              )}
              {user && user.role === "U" && !is_logged && (
                <Link
                  to="/login"
                  className="btn btn-info btn-sm btn-block font-1vw"
                >
                  Login to Bid
                </Link>
              )}
            </td>
          )}
        </tr>
      </React.Fragment>
    );
  }
}
